var PageContent = (function() {
  return {
    header: {
      mmmLogo: "/3m_theme_assets/web_apps/apps/mmmCommand/media/images/logo-3-m.svg",
      walmartLogo: "/3m_theme_assets/web_apps/apps/mmmCommand/media/images/logo-walmart.svg",
      walmartLink: "https://www.walmart.com/"
    },
    footer: {
      disclaimer:
        "*All product and company names are trademarks™ or registered® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.",
      copy: " © 3M 2019. All Rights Reserved.",
      mmmLogo: "/3m_theme_assets/web_apps/apps/mmmCommand/media/images/logo-3-m-red.svg"
    }
  };
})();

var ContentHandler = (function(content) {
  "use strict";

  function get(selector) {
    return document.querySelector(selector);
  }

  function loadContent() {
    for (var parent in content) {
      for (var child in content[parent]) {
        setContent(
          get(parent).querySelector("#mmmCommand-" + child),
          content[parent][child]
        );
      }
    }
  }

  function setContent(element, value) {
    if (element) {
      if (element.tagName === "IMG") {
        element.setAttribute("src", value);
      } else if (element.tagName === "P") {
        element.textContent = value;
      } else if (element.tagName === "A") {
        element.setAttribute("href", value);
      }
    }
  }

  function render() {
    loadContent();
  }

  return {
    render: render
  };
})(PageContent);

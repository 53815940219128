var TemplateHandler = (function(ProductTree, Handlebars) {
  "use strict";

  window.state = {
    project: null,
    key: null,
    step: 0
  };

  var sections = {
    projects: {
      id: "mmmCommand-projects",
      class: "mmmCommand-icon-selector",
      template: window.Templates.projects
    },
    where: {
      id: "mmmCommand-where",
      class: "mmmCommand-step__image-selector__item",
      template: window.Templates.where
    },
    what: {
      id: "mmmCommand-what",
      class: "mmmCommand-icon-selector",
      template: window.Templates.what
    },
    heavy: {
      id: "mmmCommand-heavy",
      class: "mmmCommand-button",
      template: window.Templates.heavy
    },
    products: {
      id: "mmmCommand-products",
      class: "mmmCommand-restart-selector",
      template: window.Templates.products
    }
  };

  function getElement(selector) {
    return document.querySelector(selector);
  }

  function getAllElements(section) {
    return getElement("#" + section.id).querySelectorAll("." + section.class);
  }

  function removeSections(step) {
    var sectionsElements = document.querySelectorAll("section");

    sectionsElements.forEach(function(el) {
      if (step < el.dataset.step) {
        el.innerHTML = " ";
        removeDataUrl(el.dataset.step);
      }
    });
  }

  function resetState() {
    window.state = {
      project: null,
      key: null,
      step: 0
    };
  }

  function renderSection(context, section) {
    if (!section) return;
    var html = section.template(context);
    var sectionElement = getElement("#" + section.id);

    sectionElement.insertAdjacentHTML("beforeend", html);
    addListeners(section);

    if (context.step !== 0) scrollToSection(section.id);
  }

  function scrollToSection(sectionId) {
    // TODO: Needs refactor
    setTimeout(function() {
      var elementToScroll = getElement("#" + sectionId);
      if (elementToScroll) {
        elementToScroll.scrollIntoView({
          behavior: "smooth"
        });
      }
    }, 100);
  }

  function updateRender(clickedStep, key) {
    removeSections(clickedStep);
    updateState({ key: key, step: clickedStep });
  }

  function updateState(newState) {
    newState = newState || {};

    if (!newState.step || newState.step <= 0) {
      resetState();
    }

    if (!window.state.project && newState.key) {
      window.state.project = newState.key;
    }

    var context = ProductTree.next(
      window.state.project,
      newState.key,
      newState.step
    );

    saveDataUrl(newState.step, newState.key);

    newState.step = context.step;
    window.state = Object.assign({}, window.state, newState);

    renderSection(context, sections[context.stepName]);
  }

  function restart() {
    scrollToSection(sections["projects"].id);
    setTimeout(function() {
      updateRender(-1, null);
    }, 700);
  }

  function selectItem(closestElement, section) {
    var elements = getAllElements(section);

    var closestElementStep = closestElement.closest("#" + section.id).dataset
      .step;

    elements.forEach(function(element) {
      element.classList.remove("mmmCommand-is-selected");
    });

    event.target.classList.add("mmmCommand-is-selected");

    updateRender(closestElementStep, closestElement.getAttribute("id"));
  }

  function addListeners(section) {
    var sectionSelector = section.id;
    var childrenSelector = section.class;
    var sectionElement = getElement("#" + sectionSelector);

    sectionElement.addEventListener(
      "click",
      function(event) {
        var closestElement = event.target.classList.contains(childrenSelector)
          ? event.target
          : event.target.closest("." + childrenSelector);

        if (closestElement) {
          sectionSelector.includes("products")
            ? restart()
            : selectItem(closestElement, section);
        }
      },
      false
    );
  }
  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  function isNumber(number) {
    return /^\d+$/.test(number);
  }

  function updateSelection(id) {
    getElement("#" + id).classList.add("mmmCommand-is-selected");
  }

  function updateUrl(url) {
    window.history.pushState(null, null, url);
  }

  function saveDataUrl(step, data) {
    if (!step || !data || isSafari()) return;

    var url = window.location.href;

    if (url.includes(step) && url.includes(data)) return;

    if (step === "0" || !url.includes("#")) {
      url = "#/" + step + "/" + data + "/";
    } else {
      url = url.substr(url.indexOf("#"));
      url = url.includes(step) ? url.substr(0, url.indexOf(step)) : url;
      url += step + "/" + data + "/";
    }
    updateUrl(url);
  }

  function removeDataUrl(step) {
    if (isSafari()) return;

    var url = window.location.href;

    if (url.includes("#")) {
      url = url.substr(url.indexOf("#"));
      url = url.substr(0, url.indexOf(step) - 1);
      url = step === "0" ? " " : url;

      updateUrl(url);
    }
  }

  function reloadData(url) {
    var arr = url.substr(url.indexOf("#") + 1).split("/");

    updateRender(-1, null);

    for (var i = 0; i < arr.length; i++) {
      if (isNumber(arr[i])) {
        var step = arr[i];
        var key = arr[i + 1];

        updateRender(step, key);
        updateSelection(key);
      }
    }
  }

  function render() {
    var url = window.location.href;

    if (!url.includes("#") || isSafari()) {
      updateState();
    } else {
      reloadData(url);
    }
  }

  return {
    render: render
  };
})(ProductTree, Handlebars);

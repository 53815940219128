var ProductTree = (function(data) {
  "use strict";

  function next(project, key, step) {
    var steps = ["projects", "where", "what", "heavy", "products"];
    var cData = JSON.parse(JSON.stringify(data));
    step = !step || step < 0 ? 0 : step;

    if (!project && !key) {
      return Object.assign({}, cData.projects, {
        step: step,
        stepName: steps[step]
      });
    }

    var content = cData.content;

    if (project && step < steps.length - 1) {
      while (step < steps.length - 2) {
        step++;
        var result = content[project][steps[step]];
        if (result && result.items.length > 0) {
          result.items = result.items.filter(function(el) {
            return el.parent === key;
          });

          if (result.items.length <= 0) continue;

          return Object.assign({}, result, {
            step: step,
            stepName: steps[step]
          });
        }
      }
    }

    if (step >= 3) {
      step++;
      var items = content[project]["products"][key];

      return {
        items: items,
        question:
          "We recommend " +
          items.length +
          " product" +
          (items.length > 1 ? "s" : ""),
        step: step,
        stepName: steps[step]
      };
    }
  }

  return {
    next: next
  };
})(Data);

var Carousel = (function(Swiper) {
  "use strict";

  var carousel = {};

  var options = {
    preloadImages: false,
    loop: true,
    lazy: {
      loadOnTransitionStart: true
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };

  function initializeSwiper() {
    carousel = new Swiper(".mmmCommand-carousel", options);
  }

  function getDataContext() {
    return fetch(window.mmmCommandJsonURL + "carousel.json", {
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }).then(function(response) {
      return response.json();
    });
  }

  function renderTemplate(data) {
    var carouselContainer = document.querySelector(
      "#mmmCommand-carousel-container"
    );

    var html = window.Templates.carousel(data);
    carouselContainer.insertAdjacentHTML("beforeend", html);
  }

  function addListener() {
    document
      .querySelector(".swiper-play-control")
      .addEventListener("click", function(event) {
        if (event.target.classList.contains("swiper-play-control")) {
          event.preventDefault();
          event.target.classList.contains("paused")
            ? carousel.autoplay.stop()
            : carousel.autoplay.start();
          event.target.classList.toggle("paused");
        }
      });
  }

  function start() {
    getDataContext().then(function(data) {
      renderTemplate(data);
      initializeSwiper();
      addListener();
    });
  }

  Handlebars.registerHelper("srcsetStringBuilder", function() {
    var str = [];
    for (var i = 0; i < this.images.length; i++) {
      var images = this.images[i];

      str.push(images.join(" "));
    }

    return str.join(", ");
  });

  return {
    start: start
  };
})(Swiper);
